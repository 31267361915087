import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Delete, Edit } from '@mui/icons-material';

const UserList = ({ users, onEditUser, onDeleteUser, }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Profile</TableCell>
                        <TableCell>Team</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.profile}</TableCell>
                            <TableCell>{user.team?.name}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                <IconButton onClick={() => onEditUser(user)} color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => onDeleteUser(user)} color="secondary">
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserList;
