import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { ButtonGroup, Button, Box } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const dataOptions = ["Hier", "Aujourd'hui", "Cette semaine", "Ce mois"];

const ModelPie = () => {
    const data = {
        labels: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
        datasets: [
            {
                label: 'Restaurants Visit\u00E9s',
                data: [10, 15, 12, 8, 20, 25, 5],
                backgroundColor: '#3f51b5',
            },
            {
                label: 'Accords Obtenus',
                data: [2, 5, 3, 4, 6, 7, 1],
                backgroundColor: '#f50057',
            },
        ],
    };

    const [selected, setSelected] = useState(dataOptions[1]);

    const handleSelect = (option) => {
        setSelected(option);
        //onSelect(option); // Appelle la fonction pour mettre à jour le graphique
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <ButtonGroup
                    variant="outlined"
                    color="primary"
                    aria-label="chart selection"
                    sx={{ width: '100%' }}
                >
                    {dataOptions.map((option) => (
                        <Button
                            key={option}
                            onClick={() => handleSelect(option)}
                            variant={selected === option ? "contained" : "outlined"}
                            sx={{ flexGrow: 1 }}
                        >
                            {option}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '200px',
                maxHeight: '400px',
                margin: '0 auto',
                marginTop: 15,
                position: 'relative'
            }}>
                <Pie data={data} />
            </div>
        </>
    )
}

export default ModelPie;