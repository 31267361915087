import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Pour le nombre de visites
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Pour les accords obtenus
import VerifiedIcon from '@mui/icons-material/Verified'; // Pour les accords validés
import PersonIcon from '@mui/icons-material/Person'; // Pour le client freemium

const dashboardData = [
    {
        icon: <VisibilityIcon style={{ fontSize: 40, color: '#FF9800' }} />,
        title: 'Visites',
        value: '49',
        footer: 'Aujourd\'hui',
        footerStyle: { color: 'red' },
    },
    {
        icon: <CheckCircleIcon style={{ fontSize: 40, color: '#4CAF50' }} />,
        title: 'Accords obtenus',
        value: '34',
        footer: 'Cette semaine',
    },
    {
        icon: <VerifiedIcon style={{ fontSize: 40, color: '#F44336' }} />,
        title: 'Installations',
        value: '75',
        footer: 'Cette semaine',
    },
    {
        icon: <PersonIcon style={{ fontSize: 40, color: '#00ACC1' }} />,
        title: 'Primes',
        value: '+245',
        footer: 'Mois',
    },
];

const Summary = () => {
    return (
        <Box p={2}>
            <Grid container spacing={3}>
                {dashboardData.map((data, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card>
                            <Box display="flex" alignItems="center" p={2}>
                                <Box mr={2}>{data.icon}</Box>
                                <CardContent>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {data.title}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {data.value}
                                    </Typography>
                                    <Typography variant="body2" style={data.footerStyle}>
                                        {data.footer}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Summary;
