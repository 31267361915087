import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, BarElement);

const ModelBar = () => {
    const data_0 = {
        labels: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
        datasets: [
            {
                label: 'Restaurants Visit\u00E9s',
                data: [10, 15, 12, 8, 20, 25, 5],
                backgroundColor: '#3f51b5',
            },
            {
                label: 'Accords Obtenus',
                data: [2, 5, 3, 4, 6, 7, 1],
                backgroundColor: '#f50057',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <Bar data={data_0} options={options} />
    )
}

export default ModelBar;