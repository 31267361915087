import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchAndStoreCompanies as fetchAndStoreLocalyCompanies } from './dataaccess/db';
import { jwt } from './helpers/jwt';
import MainRoutes from './components/MainRoutes';
import "./styles/main.scss";
import { SETTINGS } from './constants';

const { REACT_APP_REFRESH_INTERVAL } = process.env;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedPreferences = localStorage.getItem(SETTINGS);
    return savedPreferences ? JSON.parse(savedPreferences).darkMode : false;
  });
  const [open, setOpen] = useState(false);
  const { alert, loading } = useSelector(state => state);
  const isTokenExpired = jwt.isExpired();

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  useEffect(() => {
    setOpen(alert.message != null);
  }, [alert]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isTokenExpired)
        await fetchAndStoreLocalyCompanies();
    };

    fetchData();

    const intervalId = setInterval(fetchData, REACT_APP_REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [isTokenExpired]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    const updatedPreferences = {
      darkMode: newDarkMode,
    };
    localStorage.setItem(SETTINGS, JSON.stringify(updatedPreferences));
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ margin: '10px' }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.type}
          sx={{ width: '100%', fontSize: '1rem' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Router>
        <MainRoutes
          darkMode={darkMode} toggleDarkMode={toggleDarkMode}
        />
      </Router>
    </ThemeProvider>
  );
};

export default App;
