import React, { useEffect, useState } from "react";
import { Typography, CircularProgress, Button, Card, CardContent, CardActions, Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, DialogActions, FormControl, OutlinedInput, InputLabel, ListItemText, Checkbox } from "@mui/material";
import { TeamService } from "../services/teams/team.service";
import { UserService } from "../services/users";
import TeamList from "../components/team/TeamList";
import UserList from "../components/team/UserList";
import { profiles } from "../helpers/utils";
import { CURRENT_USER } from "../constants";

export default function TeamManagementPage() {

    const [user] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showTeams, setShowTeams] = useState(true);
    const [openTeamDialog, setOpenTeamDialog] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const [newTeamLeader, setNewTeamLeader] = useState(undefined);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [editingTeam, setEditingTeam] = useState(null);
    const [teamNameError, setTeamNameError] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);
    const [loadSaveTeam, setLoadSaveTeam] = useState(false);

    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [userTeam, setUserTeam] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [loadSaveUser, setLoadSaveUser] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(false);
    const [filteredTeams, setFilteredTeams] = useState(false);

    const currentUser = {
        profile: 'SUPERADMIN',
        id: '805ce146-aa4a-40c5-bd86-f30938141db7',
        teamId: 1
    }
    useEffect(() => {
        fetchTeams();
        fetchUsers();
    }, []);

    const fetchTeams = async () => {
        try {
            const fetchedTeams = await TeamService.getList();
            setTeams(fetchedTeams);
            const teamsFiltered = currentUser.profile === 'MANAGER' ? fetchedTeams.filter(team => team.leadId === currentUser.id) : fetchedTeams;
            setFilteredTeams(teamsFiltered);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching teams:", error);
            setLoading(false);
        }
    };
    const fetchUsers = async () => {
        try {
            const fetchedUsers = await UserService.getUsers();
            setUsers(fetchedUsers);
            const usersFiltered = currentUser.profile === 'MANAGER' ? fetchedUsers.filter(user => user.teamId === currentUser.teamId) : fetchedUsers;
            setFilteredUsers(usersFiltered);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };

    const handleEditTeam = (team) => {
        setNewTeamName(team.name);
        setNewTeamLeader(team.leadId);
        setEditingTeam(team);
        setOpenTeamDialog(true);
        console.log(`Edit team with ID: ${team.id}`);
    };

    const toggleView = () => {
        const usersFiltered = currentUser.profile === 'MANAGER' ? users.filter(user => user.teamId === currentUser.teamId) : users;
        setFilteredUsers(usersFiltered);
        setShowTeams(!showTeams);
    };

    const handleOpenTeamDialog = () => {
        setNewTeamName('');
        setNewTeamLeader('');
        setSelectedUsers([]);
        setEditingTeam(null);
        setOpenTeamDialog(true);
    }

    const handleCloseTeamDialog = () => {
        setOpenTeamDialog(false)
    }

    const handleTeamNameChange = (e) => {
        setNewTeamName(e.target.value);
        if (e.target.value.trim() !== '') {
            setTeamNameError(false);
        }
    };

    const handleDeleteTeam = (team) => {
        setTeamToDelete(team);
        setOpenDeleteDialog(true);
    };

    const handleConfirmDeleteTeam = async (team) => {
        await TeamService.deleteTeam(team.id);
        await fetchTeams();
        setOpenDeleteDialog(false);

    }

    const handleSave = () => {
        if (newTeamName.trim() === '') {
            setTeamNameError(true);
            return;
        }
        setTeamNameError(false);
        handleSaveTeam();
    }

    const handleSaveTeam = async () => {
        setLoadSaveTeam(true);
        const teamData = {
            name: newTeamName,
            leadId: newTeamLeader,
        };
        let membersData = [...selectedUsers];
        if (newTeamLeader && !membersData.includes(newTeamLeader)) {
            membersData.unshift(newTeamLeader);
        }
        let teamId;
        if (editingTeam) {
            const data = {
                userIds: editingTeam.users.map(user => user.id),
                teamId: null
            }
            const updateUsersPromise = UserService.updateUsersTeam(data);
            const updateTeamPromise = TeamService.updateTeam(editingTeam.id, teamData);
            await Promise.all([updateUsersPromise, updateTeamPromise]);
            teamId = editingTeam.id;
        } else {
            const createdTeam = await TeamService.createTeam(teamData);
            teamId = createdTeam.id;
        }
        if (membersData.length > 0) {
            const data = {
                userIds: membersData,
                teamId: teamId
            }
            await UserService.updateUsersTeam(data);
        }
        await Promise.all([fetchTeams(), fetchUsers()]);
        setLoadSaveTeam(false);
        setOpenTeamDialog(false);
    }

    const handleEditUser = (user) => {
        setUserName(user?.firstName + ' ' + user?.lastName);
        setUserEmail(user.email);
        setUserProfile(user.profile);
        setUserTeam(user.teamId);
        setEditingUser(user);
        setOpenUserDialog(true)
    };

    const handleUsersChange = (event) => {
        const { target: { value } } = event;
        setSelectedUsers(typeof value === 'string' ? value.split(',') : value);
    };

    const handleDeleteUser = (userId) => {
        console.log(`Delete user with ID: ${userId}`);
    };
    const handleSaveUser = async () => {
        setLoadSaveUser(true);
        if (editingUser) {
            const userId = editingUser.id;
            const userData = {
                profile: userProfile,
                teamId: userTeam ? userTeam : null
            };
            const updatedUser = UserService.update(userId, userData);
        }
        await Promise.all([fetchTeams(), fetchUsers()]);
        setLoadSaveUser(false);
        setOpenUserDialog(false)
    }

    const handleCloseUserDialog = () => {
        setOpenUserDialog(false)
    }

    const handleTeamClick = (team) => {
        const teamUsers = users.filter(user => user.teamId === team.id); 
        setFilteredUsers(teamUsers);
        setShowTeams(false); 
      };

 

    return (
        <div style={{ padding: "20px" }}>
            <Typography variant="h5" gutterBottom>
                {showTeams ? "Team Management" : "User Management"}
            </Typography>

            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Card>
                        <CardContent>
                            <Typography variant="body2">
                                {showTeams ? "Displaying Teams" : "Displaying Users"}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={toggleView}>
                                {showTeams ? "View Users" : "View Teams"}
                            </Button>
                        </CardActions>
                    </Card>

                    {currentUser.profile === 'SUPERADMIN' && (
                        <div>
                            {showTeams ? (
                                <TeamList 
                                    teams={filteredTeams} 
                                    onEditTeam={handleEditTeam} 
                                    onDeleteTeam={handleDeleteTeam} 
                                    onCreateTeam={handleOpenTeamDialog} 
                                    onTeamClick={handleTeamClick}
                                />
                            ) : (
                                <UserList users={filteredUsers} onEditUser={handleEditUser} onDeleteUser={handleDeleteUser} />
                            )}
                        </div>
                    )}

                    {currentUser.profile === 'MANAGER' && (
                        <div>
                            {showTeams ? (
                                <TeamList 
                                    teams={filteredTeams} 
                                    onEditTeam={handleEditTeam} 
                                    onDeleteTeam={handleDeleteTeam} 
                                    onCreateTeam={handleOpenTeamDialog} 
                                    onTeamClick={handleTeamClick}
                                />
                            ) : (
                                <UserList users={filteredUsers} onEditUser={handleEditUser} onDeleteUser={handleDeleteUser} />
                            )}
                        </div>
                    )}
                    {user.profile === 'COMMERCIAL' && (
                        <p>Vous n'avez pas la permission de voir cette section.</p>
                    )}

                    {/* Dialog pour team */}
                    <Dialog
                        open={openTeamDialog}
                        onClose={handleCloseTeamDialog}
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '500px',
                                height: '400px',
                            },
                        }}
                    >
                        <DialogTitle>{editingTeam ? 'Update Team' : 'Create New Team'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Team Name"
                                fullWidth
                                value={newTeamName}
                                onChange={handleTeamNameChange}
                                required
                                error={teamNameError}
                                helperText={teamNameError ? 'Team Name is required' : ''}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Team Leader</InputLabel>
                                <Select
                                    value={newTeamLeader || ''}
                                    onChange={(e) => setNewTeamLeader(e.target.value)}
                                    input={<OutlinedInput label="Team Leader" />}
                                >
                                    <MenuItem value={undefined}>
                                        <em>None</em>
                                    </MenuItem>
                                    {users
                                        .filter(user => user.teamId === null || user.teamId === editingTeam?.id)
                                        .filter((user) => !selectedUsers.includes(user.id))
                                        .map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Team Members</InputLabel>
                                <Select
                                    multiple
                                    value={selectedUsers || ''}
                                    onChange={handleUsersChange}
                                    input={<OutlinedInput label="Team Members" />}
                                    renderValue={(selected) => selected.map(id => {
                                        const user = users.find(user => user.id === id);
                                        return `${user.firstName} ${user.lastName}`;
                                    }).join(', ')}
                                >
                                    {users
                                        .filter(user => user.teamId === null || user.teamId === editingTeam?.id)
                                        .filter((user) => user.id !== newTeamLeader)
                                        .map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                <Checkbox checked={selectedUsers.indexOf(user.id) > -1} />
                                                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseTeamDialog}>Cancel</Button>
                            <Button onClick={handleSave} color="primary" disabled={teamNameError} >
                                {!loadSaveTeam && "Save"}
                                {loadSaveTeam && <CircularProgress size={20} color="inherit" />}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openDeleteDialog}
                        onClose={() => setOpenDeleteDialog(false)}  // Ferme le dialogue
                    >
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Are you sure you want to delete the team: <strong>{teamToDelete?.name}</strong>?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleConfirmDeleteTeam(teamToDelete)}
                                color="primary"
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Dialog pour user */}
                    <Dialog
                        open={openUserDialog}
                        onClose={handleCloseUserDialog}
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '500px',
                                height: '400px',
                            },
                        }}
                    >
                        <DialogTitle>Update user</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Name"
                                fullWidth
                                value={userName}
                                required
                                error={teamNameError}
                                disabled
                            />
                            {/* <TextField
                                autoFocus
                                margin="dense"
                                label="Email"
                                fullWidth
                                value={userEmail}
                                required
                                error={teamNameError}
                                disabled 
                            /> */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Profile</InputLabel>
                                <Select
                                    value={userProfile || ''}
                                    onChange={(e) => setUserProfile(e.target.value)}
                                    input={<OutlinedInput label="Profile" />}
                                >
                                    {profiles
                                        .map((profile) => (
                                            <MenuItem key={profile.id} value={profile.value}>
                                                {profile.value}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={userTeam || ''}
                                    onChange={(e) => setUserTeam(e.target.value)}
                                    input={<OutlinedInput label="Team" />}
                                >
                                    <MenuItem value={undefined}>
                                        <em>None</em>
                                    </MenuItem>
                                    {teams
                                        .map((team) => (
                                            <MenuItem key={team.id} value={team.id}>
                                                {team.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseUserDialog}>Cancel</Button>
                            <Button onClick={handleSaveUser} color="primary" disabled={teamNameError} >
                                {!loadSaveUser && "Save"}
                                {loadSaveUser && <CircularProgress size={20} color="inherit" />}
                            </Button>
                        </DialogActions>
                    </Dialog>



                </>
            )}
        </div>
    );
}
