import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TeamList = ({ teams, onEditTeam, onDeleteTeam, onCreateTeam, onTeamClick }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            height: '20px',
                            backgroundColor: 'gray'
                        }}>
                        <TableCell>ID</TableCell>
                        <TableCell>Team Name</TableCell>
                        <TableCell>Leader</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                            <IconButton onClick={() => onCreateTeam()} color="primary">
                                <FontAwesomeIcon icon={faPlus} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teams.map((team) => (
                        <TableRow
                            key={team.id}
                            sx={{
                                height: '20px',
                            }}

                        >
                            <TableCell
                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => onTeamClick(team)}
                            > {team.id}
                            </TableCell>
                            <TableCell
                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => onTeamClick(team)}
                            >{team.name}</TableCell>
                            <TableCell>{team?.lead?.firstName} {team?.lead?.lastName}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                <IconButton onClick={() => onEditTeam(team)} color="primary">
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => onDeleteTeam(team)} color="secondary">
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TeamList;
