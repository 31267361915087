import React from "react";
import { Grid, Box, Paper } from "@mui/material";
import ModelBar from "../components/dashboard/ModelBar";
import ModelBar2 from "../components/dashboard/ModelBar2";
import ModelPie from "../components/dashboard/ModelPie";
import ModelLine from "../components/dashboard/ModelLine";
import Summary from "../components/dashboard/summary";

export default function DashboardPage() {
    return (
        <Box sx={{ padding: 2 }}>
            <Summary />
            <Grid container spacing={3}>
                {/* Premier graphique */}
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Nombre de visites | Accords Obtenus</h3>
                        <ModelBar />
                    </Paper>
                </Grid>
                {/* Deuxième graphique */}
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Installations et Abonnements</h3>
                        <ModelBar2 />
                    </Paper>
                </Grid>
                {/* Troisième graphique */}
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Cycle de Vie des Clients</h3>
                        <ModelPie />
                    </Paper>
                </Grid>
                {/* Quatrième graphique */}
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Accords Obtenus et Primes</h3>
                        <ModelLine />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
