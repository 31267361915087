import React from "react";
import { Typography, Box, Switch, FormControlLabel } from "@mui/material";

export default function SettingsPage({ toggleDarkMode, darkMode }) {
    return (
        <Box
            sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Préférences
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={darkMode}
                            onChange={toggleDarkMode}
                            color="primary"
                        />
                    }
                    label="Mode sombre"
                />
            </Box>
        </Box>
    );
}
