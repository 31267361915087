import { commonService } from '../generic.service';
//
const getList = () => {
    return commonService.get('teams');
}

const updateTeam = (id, data) => {
    return commonService.put(`teams/${id}/update`, data );
};


const createTeam = (data) => {
    return commonService.post(`teams`, data);
};

const deleteTeam = (id) => {
    return commonService.remove(`teams/${id}`);
};

const get = (id) => {
    return commonService.get(`teams/${id}`);
};

export const TeamService = {
    getList,
    createTeam,
    updateTeam,
    deleteTeam,
    get
};
 